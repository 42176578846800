import React from "react"
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material"

const educationData = [
  {
    date: "Sep 2007 - Jul 2010",
    location: "University of Surrey",
    title: "B.Sc. Computer Science",
    logo: "University_of_Surrey.png",
  },
  {
    date: "Sep 2010 - Jul 2011",
    location: "University of Nottingham",
    title: "M.Sc. Management of Information Technology",
    logo: "University_of_Nottingham.png",
  },
]

const Education = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md")) // Check for mobile screen
  return (
    <Box id="education" sx={{ p: isMobile ? 0 : 4 }}>
      <Container>
        <Typography variant="h2" gutterBottom textAlign="center">
          Education
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: isMobile ? 0 : 2, // Add gap for spacing between columns
          }}
        >
          {educationData.map((edu, index) => (
            <Box
              key={index}
              sx={{
                width: isMobile ? "100%" : "calc(50% - 16px)", // Two columns on desktop
                mb: 2,
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: isMobile ? 1 : 2,
                }}
              >
                {/* University Logo */}
                <CardMedia
                  component="img"
                  image={edu.logo}
                  alt={edu.location}
                  sx={{ width: 80, height: 80, objectFit: "contain", mr: 2 }}
                />
                {/* Education Details */}
                <CardContent>
                  <Typography variant="subtitle1" color="textSecondary">
                    {edu.date}
                  </Typography>
                  <Typography variant="h6">{edu.location}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {edu.title}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Education
