import React, { useState } from "react"
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "react-scroll"
import { useTheme, useMediaQuery } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import WorkIcon from "@mui/icons-material/Work"
import SchoolIcon from "@mui/icons-material/School"
import CodeIcon from "@mui/icons-material/Code"
import FolderIcon from "@mui/icons-material/Folder"

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md")) // Check for mobile screen

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open)
  }

  const handleMenuItemClick = () => {
    setDrawerOpen(false) // Close the drawer when a menu item is clicked
  }

  const navLinks = [
    { label: "About Me", to: "about", icon: <InfoIcon /> },
    { label: "Work", to: "work", icon: <WorkIcon /> },
    { label: "Education", to: "education", icon: <SchoolIcon /> },
    { label: "Skills", to: "skills", icon: <CodeIcon /> },
    { label: "Portfolio", to: "portfolio", icon: <FolderIcon /> },
  ]

  const offset = isMobile ? -56 : -64 // Adjust based on AppBar height

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navLinks.map((link) => (
          <ListItem key={link.to} disablePadding>
            <ListItemButton sx={{ display: "flex", alignItems: "center" }}>
              {" "}
              {/* Add this line */}
              <Link
                to={link.to}
                smooth
                duration={500}
                offset={offset}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }} // Add this line
                onClick={handleMenuItemClick}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  {" "}
                  {/* Adjust spacing */}
                  {link.icon}
                </ListItemIcon>
                <ListItemText primary={link.label} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <AppBar position="sticky">
      <Toolbar sx={{ justifyContent: "center" }}>
        {/* {isMobile && <Typography variant="h6" sx={{ flexGrow: 1 }}>
        My Resume
        </Typography>} */}
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{ position: "absolute", right: theme.spacing(2) }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawerContent}
            </Drawer>
          </>
        ) : (
          navLinks.map((link) => (
            <Button color="inherit" key={link.to} aria-label={link.to}>
              <Link
                to={link.to}
                href={link.to}
                smooth
                duration={500}
                offset={offset} // Apply offset here
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {link.label}
              </Link>
            </Button>
          ))
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
