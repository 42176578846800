import React from "react"
import {
  Box,
  Typography,
  Stack,
  Grid,
  Container,
  useTheme,
} from "@mui/material"
import { motion } from "framer-motion"

const skills = [
  {
    category: "Spoken languages",
    items: [
      { name: "English", level: 100 },
      { name: "Greek", level: 100 },
    ],
  },
  {
    category: "Frontend development",
    items: [
      { name: "React", level: 100 },
      { name: "GraphQL", level: 90 },
      { name: "Typescript", level: 70 },
    ],
  },
  {
    category: "Backend development",
    items: [
      { name: "Node.js", level: 90 },
      { name: "Java", level: 60 },
      { name: "C", level: 40 },
    ],
  },
  {
    category: "Mobile development",
    items: [
      { name: "Android", level: 80 },
      { name: "Swift", level: 50 },
      { name: "React Native", level: 65 },
    ],
  },
]

const Skills = () => {
  const theme = useTheme()
  return (
    <Box id="skills" sx={{ py: 4 }}>
      <Container>
        <Typography variant="h4" gutterBottom textAlign="center">
          Skills
        </Typography>
        <Grid container spacing={3}>
          {skills.map((skillCategory, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {skillCategory.category}
              </Typography>
              <Box>
                {skillCategory.items.map((skill, itemIndex) => (
                  <Stack key={itemIndex} spacing={1} sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                      {skill.name}
                    </Typography>
                    <motion.div
                      initial={{ width: 0 }}
                      whileInView={{ width: `${skill.level}%` }}
                      transition={{ duration: 0.8, ease: "easeInOut" }}
                      style={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "grey.300",
                        overflow: "hidden", // Hide excess bar during animation
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      />
                    </motion.div>
                  </Stack>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Skills
