import React from "react"
import { Container, Typography, Box, Paper, Avatar } from "@mui/material"
import { useTheme } from "@mui/material"
import SocialLinks from "./SocialLinks"

const AboutMe = () => {
  const theme = useTheme()
  return (
    <Box
      id="about"
      sx={{
        py: 4,
        backgroundImage: `url('blue-sky.jpg')`, // Replace with your background image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh", // Full viewport height
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: { xs: 2, sm: 3 }, // Smaller padding on mobile
              textAlign: "center",
              borderRadius: "16px",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.1)"
                  : "rgba(255, 255, 255, 0.8)", // Transparent in dark mode
              position: "relative",
              zIndex: 1,
              maxWidth: { xs: "90%", sm: 600 }, // Adjust max width on mobile
              width: "100%",
              color: "#000",
            }}
          >
            <Typography
              color="primary"
              variant="h3"
              component="h1"
              gutterBottom
              align="center"
              sx={{ fontWeight: "bold" }}
            >
              I'm Panayiotis Georgiou.
            </Typography>
            <Typography variant="h6" component="p" align="center" gutterBottom>
              <strong>Senior FullStack Software Engineer</strong> 👨🏻‍💻 based in
              Cyprus 🇨🇾
              {/* <br/> currently working at BDSwiss a leading financial institution, offering Forex and CFD investment services.  */}
            </Typography>

            <Avatar
              alt="Panayiotis Georgiou"
              src="profile.webp" // Replace with your avatar URL
              sx={{
                width: { xs: 100, sm: 150 }, // Smaller avatar on mobile
                height: { xs: 100, sm: 150 },
                marginBottom: 2,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Typography
              align="justify"
              variant="body1"
              sx={{
                color: "stone.200",
                fontSize: {
                  sm: "1rem", // sm:prose-base
                  lg: "1.125rem", // lg:prose-lg
                },
                "& strong": {
                  color: "stone.100",
                },
              }}
            >
              In my free time time, you can catch me plucking my{" "}
              <strong>Greek 🇬🇷 bouzouki</strong> and performing Greek Live music
              🎼 in Cyprus, playing sports depending weather or exploring{" "}
              <strong>Cyprus 🇨🇾</strong> with my drone.
            </Typography>
            <SocialLinks color={theme.palette.primary.main} />
            {/* <Typography 
        variant="body1" 
        sx={{ mb: 2, fontWeight: 400, fontFamily: 'Roboto, Arial, sans-serif' }} // Use a web-safe font as a fallback
        >
        Hello! I'm a Senior Full Stack Developer with a passion for creating web applications
        that provide excellent user experiences. I have expertise in JavaScript, React, Node.js,
        and a variety of other technologies. I love working collaboratively with teams to solve 
        complex problems and deliver high-quality solutions.
        </Typography>

        <Typography variant="body2">
          In my free time, I enjoy exploring new technologies, contributing to open-source projects, 
          and sharing my knowledge with the community.
        </Typography> */}
          </Paper>
        </Box>
      </Container>
    </Box>
  )
}

export default AboutMe
