import React, { useState } from "react"
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Container,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const portfolioItems = [
  {
    img: "EyeRes-Project.png",
    title: "EyeRes-Project",
    description: "EyeRes Project was developed during the European Space Agency (ESA) Space App Camp 2019. The purpose of the project is to enhance satellite images with artificial intelligence and machine learning.",
    link: "https://www.panayiotisgeorgiou.net/eyeres-project",
  },
  {
    img: "bouzouki-app.png",
    title: "Bouzouki App - Free of charge, Unlimited Fun",
    description: "It works completely offline the application is free to download and use at any time. An easy and effective way of learning 📖 Greek 🇬🇷 bouzouki music 🎼 scales. No internet connection required, no server required.",
    link: "https://www.bouzouki.eu",
  },
  {
    img: "theflipflopsapp.png",
    title: "The Flip Flops App 🩴 🇨🇾",
    description: "Your number one guide to all beaches in Cyprus. Free of charge ⛱ Unlimited Fun. The app is completely free of charge. The ability to use the app without the need for internet access is particularly useful for visitors from abroad who won't be charged for roaming charges.",
    link: "https://www.theflipflopsapp.com",
  },
  {
    img: "thegentlemanblogger.png",
    title: "The Gentleman Blogger",
    description: "Inspiring and empowering men. Globally spotted by Matthew Zorpas. Web development and System Administrator for the The Gentleman Blogger website.",
    link: "http://www.thegentlemanblogger.com",
  },
  {
    img: "leafmeter.png",
    title: "Leaf Meter - International SpaceApps Challenge 2014",
    description: "Leaf meter was selected by the judging panel to represent Cyprus in the global Space Apps competition 2014!",
    link: "https://www.panayiotisgeorgiou.net/leaf-meter-international-spaceapps-challenge-2014",
  },
  
  // Add more portfolio items as needed
]

const Portfolio = () => {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleOpen = (item) => {
    setSelectedItem(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedItem(null)
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md")) // Check for mobile screen
  return (
    <Box id="portfolio" sx={{ py: 4 }}>
      <Container>
        <Typography variant="h4" gutterBottom textAlign="center">
          Portfolio
        </Typography>
        <ImageList variant="masonry" cols={isMobile ? 2 : 3} gap={16}>
          {portfolioItems.map((item, index) => (
            <ImageListItem key={index} onClick={() => handleOpen(item)}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                style={{ cursor: "pointer", borderRadius: "8px" }}
              />
              <ImageListItemBar title={item.title} position="below"/>
            </ImageListItem>
          ))}
        </ImageList>

        {/* Dialog for showing more details */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            {selectedItem?.title}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" align="justify">{selectedItem?.description}</Typography>
            <Box mt={2}>
              <img
                src={selectedItem?.img}
                alt={"Example of a project"}
                style={{ width: "100%", borderRadius: "8px" }}
              />
            </Box>
            <Button
              variant="contained"
              sx={{ top: 10 }}
              size="small"
              disableElevation
              href={selectedItem?.link}
              target="_blank"
              aria-label="Learn more"
            >
              Learn more
            </Button>
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  )
}

export default Portfolio
