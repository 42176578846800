import React from "react"
import { Box, IconButton, SvgIcon } from "@mui/material"
import {
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  X,
  YouTube,
} from "@mui/icons-material"

const StackOverflowIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15 21h-10v-2h10v2zm6-11.665l-1.621-9.335-1.993.346 1.62 9.335 1.994-.346zm-5.964 6.937l-9.746-.975-.186 2.016 9.755.879.177-1.92zm.538-2.587l-9.276-2.608-.526 1.954 9.306 2.5.496-1.846zm1.204-2.413l-8.297-4.864-1.029 1.743 8.298 4.865 1.028-1.744zm1.866-1.467l-5.339-7.829-1.672 1.14 5.339 7.829 1.672-1.14zm-2.644 4.195v8h-12v-8h-2v10h16v-10h-2z" />
    </SvgIcon>
  )
}

const SocialLinks = (props) => {
  const socialLinks = [
    {
      href: "https://github.com/georgioupanayiotis",
      icon: <GitHub />,
      name: "Github",
    },
    {
      href: "https://stackoverflow.com/users/1417689/panayiotis-georgiou",
      icon: <StackOverflowIcon />,
      name: "stackoverflow",
    },
    {
      href: "https://linkedin.com/in/georgioupanayiotis",
      icon: <LinkedIn />,
      name: "LinkedIn",
    },
    { href: "https://twitter.com/panay_georgiou", icon: <X />, name: "X" },
    {
      href: "https://www.facebook.com/georgioupanayiotis",
      icon: <Facebook />,
      name: "Facebook",
    },
    {
      href: "https://instagram.com/panayiotisgeorgiou",
      icon: <Instagram />,
      name: "Instagram",
    },
    {
      href: "https://www.youtube.com/c/PanayiotisGeorgiou?sub_confirmation=1",
      icon: <YouTube />,
      name: "Youtube",
    },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 0, // Adjust spacing as needed
      }}
    >
      {socialLinks.map((link, index) => (
        <IconButton
          aria-label={link?.name}
          key={index}
          href={link.href}
          target="_blank"
          rel="noopener"
          sx={{ color: props?.color }}
        >
          {link.icon}
        </IconButton>
      ))}
    </Box>
  )
}

export default SocialLinks
