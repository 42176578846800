import React from "react"
import Navbar from "./components/Navbar"
import AboutMe from "./components/AboutMe"
import Work from "./components/Work"
import Education from "./components/Education"
import Skills from "./components/Skills"
import Portfolio from "./components/Portfolio"
import Footer from "./components/Footer"

const App = () => {
  return (
    <>
      <Navbar />
      <AboutMe />
      <Work />
      <Education />
      <Skills />
      <Portfolio />
      <Footer />
    </>
  )
}

export default App
