import React, { useState } from "react"
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Container,
  useMediaQuery,
  useTheme,
  Link,
  Button,
} from "@mui/material"

const workExperience = [
  {
    date: "2017 - Present",
    company: "BDSwiss (Nicosia / Limassol, Cyprus)",
    title: "Senior Software Engineer",
    description:
      "As a Senior Full Stack Developer and Team Leader at BDSwiss, I spearheaded the development and maintenance of critical web applications like Dashboard, and CRM, utilizing React, TypeScript, Node.js, PostgreSQL, AWS and more within a Scrumban framework. My leadership involved providing technical guidance, conducting code reviews, and handling production deployments.",
    logo: "BDSwiss-Logo.webp", // Add company logo URL
    companyLink: "https://bdswiss.com",
    style: "50%",
  },
  {
    date: "2016 - Present",
    company: "Freelancer, Remote",
    title: "Software Engineer",
    description:
      "Seek, contact new customers. Remote lead the development of projects in a small team. Discussing improvements about the assigned projects. Plan software structure (both functional and technological plans). Research and implementation through agile methodology.",
    logo: "Panayiotis-Georgiou-Logo.jpg", // Add company logo URL
    companyLink: "https://panayiotisgeorgiou.net",
    style: "50%",
  },
  {
    date: "2015 - 2016",
    company: "Uniqodo LTD,  London UK",
    title: "Software Engineer",
    description:
      "Develop technical website solutions for a start up, using PHP, MYSQL, JavaScript, jQuery, CSS and HTML5. Worked with AWS ec2, various API’s as well as Symfony2 framework.",
    logo: "uniqodo.png",
    companyLink: "https://www.uniqodo.com",
    style: "50%",
  },
  {
    date: "2014 - 2015",
    company: "Royal Opera House, London UK",
    title: "Digital Developer",
    description:
      "Worked as part of the Digital Development team, to design, develop, deploy, maintain and iterate server-side and client-side code for the Royal Opera House website. Fixing Front-end problems reported by the content team. Technologies used HTML (Twig), CSS, JavaScript integrated with PHP Symfony Framework (MVC, MySQL, ElasticSearch). Support back-end especially in booking periods (AWS). Also daily task was to maintain and keep up to date (security) WordPress CMS for the content team.",
    logo: "Royal_Opera_House_logo_new.jpg",
    companyLink: "https://www.uniqodo.com",
    style: "0",
  },
  {
    date: "2012 - 2014",
    company: "Charterhouse (Bringing Ideas to Life), London UK",
    title: "Junior Digital Developer",
    description:
      "Participated on various projects regarding web development using HTML5,CSS 3, JQuery,PHP,MySQL, c# and .NET languages. Another part of my job was maintenance and support of existing client systems both internally and externally.",
    logo: "charterhouse.webp",
    companyLink: "https://www.charterhouseproduction.com",
    style: "0",
  },
]

const Work = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")) // Check for mobile screen
  const [showFullDescription, setShowFullDescription] = useState({}) // State to track which descriptions to show in full

  const toggleDescription = (index) => {
    setShowFullDescription((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state for the clicked item
    }))
  }
  return (
    <Box id="work" sx={{ py: 4 }}>
      <Container>
        <Typography variant="h4" gutterBottom textAlign="center">
          Work Experience
        </Typography>
        {workExperience.map((work, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              p: isMobile ? 1 : 2,
              mb: isMobile ? 3 : 2, // Add margin between cards
            }}
          >
            {" "}
            {/* Make card display flex */}
            <CardContent
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              {work.logo && ( // Conditionally render CardMedia if logo exists
                <CardMedia
                  component="img"
                  image={work.logo}
                  alt={work.company}
                  sx={{
                    width: 80,
                    height: 80,
                    objectFit: "contain",
                    borderRadius: work.style,
                    // mr: 2
                    mr: isMobile ? 0 : 2, // Remove margin on mobile
                    mb: isMobile ? 2 : 0, // Add margin bottom on mobile
                  }}
                />
              )}
              <Box>
                <Typography
                  variant="h1"
                  color="primary"
                  gutterBottom
                  fontSize={25}
                  fontWeight={400}
                >
                  {work.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Link
                    href={work.companyLink}
                    underline="none"
                    target="_blank"
                    rel="noopener"
                  >
                    {work.company}
                  </Link>{" "}
                  | {work.date}
                </Typography>
                <Box>
                  {" "}
                  {/* Wrap the description and button in a Box */}
                  <Typography variant="body1" sx={{ mt: 2 }} align="justify">
                    {isMobile && !showFullDescription[index]
                      ? `${work.description.substring(0, 200)}...` // Remove ellipsis
                      : work.description}
                  </Typography>
                  {isMobile &&
                    work.description.length > 100 && ( // Show button only if description is longer than 100 characters
                      <Button
                        aria-label={
                          showFullDescription[index] ? "Show Less" : "Show More"
                        }
                        size="small"
                        variant="text"
                        onClick={() => toggleDescription(index)}
                        sx={{ mt: 1 }}
                      >
                        {showFullDescription[index] ? "Show Less" : "Show More"}
                      </Button>
                    )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Container>
    </Box>
  )
}

export default Work
