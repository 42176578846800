import { Box, IconButton, Typography } from "@mui/material"
import SocialLinks from "./SocialLinks"
import { ArrowUpward } from "@mui/icons-material"

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling
    })
  }

  return (
    <Box sx={{ position: "relative" }}>
      {" "}
      {/* Add relative positioning to the outer Box */}
      <IconButton
        aria-label="Back to top"
        sx={{
          position: "absolute",
          top: -20, // Adjust position as needed
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "primary.main",
          color: "white",
          "&:hover": { backgroundColor: "primary.main" }, // Add hover effect
        }}
        onClick={scrollToTop} // Call scrollToTop on click
      >
        <ArrowUpward />
      </IconButton>
      <Box
        component="footer"
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          py: 3,
          mt: 4,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Connect with Me
        </Typography>
        <SocialLinks color={"white"} /> {/* Use the SocialLinks component */}
        <Typography variant="body2" sx={{ mt: 2 }}>
          © 2024 Panayiotis Georgiou. All rights reserved.
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
